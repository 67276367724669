import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CalcPage} from './calc/calc.page';
import {TabsPage} from './calc/tabs/tabs.page';

const routes: Routes = [
    {
        path: '',
        // redirectTo: 'folder/Inbox',
        redirectTo: '/calc/tabs/vehicle',
        pathMatch: 'full'
    },
    // {
    //   path: 'folder/:id',
    //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
    // },
    {
        path: 'calc/tabs',
        component: TabsPage,
        loadChildren: () => import('./calc/tabs/tabs.module').then( m => m.TabsPageModule)
    },
    // {
    //     path: 'calc',
    //     component: CalcPage,
    //     loadChildren: () => import('./calc/calc.module').then( m => m.CalcPageModule)
    // },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
